@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&family=Russo+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}


:root {
	--font-poppins: 'Poppins', sans-serif;
	--font-russo: 'Russo One', sans-serif;
	--dark-white: #FFFFFF;
	--dark-bg-1: #0A0A0B;
	--dark-bg-2: #14161B;
	--dark-input-button: #1D2025;
	--dark-text-secondary: #C5C5C5;
	--dark-text-grey: #797F8A;
	--dark-borders-hover-button: #2D3139;
	--dark-alert-tip: #323740;
	--dark-gradient: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%, #FFF6A4 95.08%);
}

body {
	background: var(--dark-bg-1);
	color: #fff;
	font-family: var(--font-poppins);
	scroll-behavior: smooth;
}


/** Common Styles **/
@media only screen and (min-width: 1200px) {
	.container {
		max-width: 1346px;
	}
}



/*Home page style*/
.collectionActivitySec {
	padding: 60px 0px;
	position: relative;

	.container {
		z-index: 3;
		position: relative;
	}

	&:before {
		content: '';
		position: absolute;
		right: 0px;
		top: -30%;
		width: 793px;
		max-width: 100%;
		height: 100%;
		background: url('/assets/images/colActBg1.png') no-repeat right top;
		background-size: contain;
		z-index: -2;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0px;
		bottom: -35%;
		width: 100%;
		height: 100%;
		background: url('/assets/images/colActBg2.png') no-repeat left bottom;
		background-size: contain;
		z-index: -2;
	}

	.card {
		background: #15171C;
		border-radius: 20px;
		padding: 28px;
		width: 100%;
		height: calc(100% - 0.25rem);

		.nav-tabs {
			border-radius: 8px;
			background: #191D22;
			height: 40px;
			backdrop-filter: blur(19px);
			border-bottom: 0px;

			li {
				a {
					color: var(--dark-text-secondary);
					text-align: center;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;
					opacity: 0.6;
					border: 0px;

					&:hover,
					&.active {
						border-radius: 8px;
						background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
						color: var(--dark-bg-1);
						text-align: center;
						font-size: 16px;
						font-style: normal;
						line-height: 24px;
						border: 0px;
						opacity: 1;
					}

					&.active {
						font-weight: 600;
					}

					&:hover {
						cursor: pointer;
					}
				}
			}

			&.checkTab {
				li {
					a {

						&:hover,
						&.active {
							background: #2E343D;
							backdrop-filter: blur(19px);
							color: #FFA900;
						}
					}
				}
			}

			&.threeTab {
				width: 100%;

				li {
					width: 33.33%;
				}
			}
		}

		select {
			padding: 8px 16px;
			padding-right: 40px;
			border-radius: 8px;
			border: 1px solid #2E343D;
			background: #191D22 url('/assets/images/angleDown.svg') no-repeat right 16px center;
			appearance: none;
			font-size: 16px;
			line-height: 24px;
			color: #f6f6f6;
			height: 40px;

			@media(max-width:767px) {
				margin-top: 15px;
			}
		}

		table {
			margin-bottom: 0px;

			thead {
				th {
					color: #595F6A;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px;
					border-bottom: 0px;
					border-top: 0px;
					padding: 0.75rem 0.4rem;

					@media(max-width:767px) {
						white-space: nowrap;
					}
				}
			}

			tbody {
				tr {

					td {
						vertical-align: middle;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px;
						color: #f6f6f6;
						border-top: 0px;
						padding: 0.75rem 0.4rem;

						h3 {
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: 24px;
							color: #f6f6f6;
							margin-bottom: 0px;
						}

						&:first-child {
							border-top-left-radius: 20px;
							border-bottom-left-radius: 20px;
						}

						&:last-child {
							border-top-right-radius: 20px;
							border-bottom-right-radius: 20px;
						}

						&:hover {
							background-color: transparent;

							td {
								background-color: #191D22;
							}
						}

						.labelCnt {
							min-height: 28px;
							padding: 4px 8px;
							border-radius: 8px;
							background: #191D22;
							white-space: nowrap;
							text-transform: capitalize;
						}

						@media(max-width:767px) {
							white-space: nowrap;
						}
					}
				}

				.smTxt {
					font-size: 12px;
				}

				.lgTxt {
					font-size: 20px;
				}
			}
		}
	}

	//@media(min-width:1200px){
	//	padding-bottom:200px;
	//}
}

.tabSliderSec {
	position: relative;
	z-index: 4;
	padding: 60px 0px 60px;

	.card {
		padding: 0px;
		border: none;
		width: 100%;
		overflow: hidden;
		position: relative;
		border-radius: 16px;
		height: 318px;
		width: 308px;
		max-width: 308px;

		img,
		video {
			&.banner {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 16px;
				min-height: 318px;
				max-height: 318px;
				max-width: 308px;
				min-width: 308px;
			}
		}

		.infoCnt {
			width: 100%;
			padding: 12px;
			fill: rgba(13, 13, 13, 0.80);
			background: rgba(13, 13, 13, 0.80);
			backdrop-filter: blur(19px);
			position: absolute;
			left: 0px;
			bottom: 0px;

			h3 {
				&.heading {
					color: #f6f6f6;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
					margin-bottom: 0px;
					margin-right: 5px;

					@media(max-width:991px) {
						font-size: 14px;
					}
				}
			}

			.valueCnt {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color: #e5e5e5;

				span {
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					color: #f6f6f6;
				}
			}
		}
	}

	.nav-tabs {
		border-radius: 8px;
		background: #191D22;
		height: 36px;
		backdrop-filter: blur(19px);
		border-bottom: 0px;
		width: auto;
		display: inline-flex;

		@media(max-width:992px) {
			height: auto;
			justify-content: center;
		}

		li {
			a {
				color: var(--dark-text-secondary);
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				opacity: 0.6;
				border: 0px;
				padding: 6px 20px;

				&:hover,
				&.active {
					border-radius: 8px;
					background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
					color: var(--dark-bg-1);
					text-align: center;
					font-size: 16px;
					font-style: normal;
					line-height: 24px;
					border: 0px;
					opacity: 1;
				}

				&.active {
					font-weight: 600;
				}

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.slick-slider {
		.slick-list {
			min-height: 318px;

			//margin: 0px -14px;
			@media(max-width:1199px) {
				padding-top: 30px;
			}
		}

		.slick-next {
			width: 40px;
			height: 40px;
			background: #191D22;
			border-radius: 8px;
			position: absolute;
			top: -55px;
			right: 62px;

			@media screen and (max-width: 1200px) and (min-width: 991px) {
				right: 15px;
			}

			@media(max-width:992px) {
				top: 0px;
				right: 15px;
			}

			img {
				transform: rotate(-90deg);
				opacity: 0.5;
			}

			&:before {
				display: none;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}

		.slick-prev {
			width: 40px;
			height: 40px;
			background: #191D22;
			border-radius: 8px;
			position: absolute;
			top: -55px;
			right: 112px;
			left: auto;

			@media screen and (max-width: 1200px) and (min-width: 991px) {
				right: 65px;
			}

			@media(max-width:992px) {
				top: 0px;
				right: 65px;
			}

			img {
				transform: rotate(90deg);
				opacity: 0.5;
			}

			&:before {
				display: none;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}

		.slick-track {

			/* the slides */
			.slick-slide {
				padding: 0 14px;
				max-height: 318px;

				@media (max-width: 1199px) {
					padding: 0 28px 0 0px;
				}

				//  min-width: 308px;
				//  max-width: 308px;
				//max-width: 468px;

				//padding-right: 25px;				
			}

			// .slick-active:last-child{
			// 	padding-right: 0px;
			// }
		}

	}

	// @media(min-width:1200px){
	// 	margin-top:-150px;
	// }

}


.FeaturedSec {
	padding: 60px 0px 60px;
	// padding-top:100px;
	background: url('/assets/images/featuredBg.png') no-repeat right top -50px;
	background-size: contain;
	position: relative;
	z-index: 5;

	.card {
		padding: 0px;
		border: none;
		width: 100%;
		overflow: hidden;
		position: relative;
		border-radius: 16px;
		height: 318px;
		width: 420px;

		@media (max-width: 525px) {
			width: 308px;
		}

		img,
		video {
			&.banner {
				width: 100%;
				height: 320px;
				object-fit: cover;
				border-radius: 16px;
				min-height: 318px;
				max-height: 318px;
				max-width: 420px;
				min-width: 420px;

				@media (max-width: 525px) {
					max-width: 308px;
					min-width: 308px;
				}
			}
		}

		.infoCnt {
			width: 100%;
			padding: 12px;
			fill: rgba(13, 13, 13, 0.80);
			background: rgba(13, 13, 13, 0.80);
			backdrop-filter: blur(19px);
			position: absolute;
			left: 0px;
			bottom: 0px;

			.infoTop {
				margin-top: -50px;
			}

			h3 {
				&.heading {
					color: #f6f6f6;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
					margin-bottom: 0px;
					margin-right: 5px;

					@media(max-width:991px) {
						font-size: 14px;
					}
				}
			}

			.valueCnt {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				color: #e5e5e5;

				span {
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					color: #f6f6f6;
				}
			}

			.profPic {
				width: 80px;
				height: 80px;
				object-fit: cover;
				border-radius: 12px;
			}
		}
	}

	.slick-slider {

		.slick-list {
			min-height: 318px;
			//margin: 0px -14px;
			// @media(max-width:992px){
			// 	padding-top:30px;
			// }
		}

		.slick-next {
			width: 40px;
			height: 40px;
			background: #191D22;
			border-radius: 8px;
			position: absolute;
			top: -65px;
			right: 62px;

			@media screen and (max-width: 1199px) {
				right: 15px;
			}

			//@media(max-width:992px){
			//	top:0px;
			//	right:0px;
			//}

			img {
				transform: rotate(-90deg);
				opacity: 0.5;
			}

			&:before {
				display: none;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}

		.slick-prev {
			width: 40px;
			height: 40px;
			background: #191D22;
			border-radius: 8px;
			position: absolute;
			top: -65px;
			right: 112px;
			left: auto;

			@media screen and (max-width: 1199px) {
				right: 65px;
			}

			//@media(max-width:992px){
			//	top:0px;
			//	right:50px;
			//}

			img {
				transform: rotate(90deg);
				opacity: 0.5;
			}

			&:before {
				display: none;
			}

			&:hover {
				img {
					opacity: 1;
				}
			}
		}

		/* the slides */
		.slick-slide {
			padding: 0 14px;
			max-height: 318px;

			@media (max-width: 1199px) {
				padding: 0 28px 0 0px;
			}
		}
	}
}

.notableSec {
	padding: 60px 0px 60px;
	// padding-top:150px;
	background: url('/assets/images/notableBg.png') no-repeat left -150px bottom -50px;

	&.tabSliderSec {
		.slick-slider {

			.slick-list {
				min-height: 318px;

				//margin: 0px -14px;
				@media(max-width:992px) {
					padding-top: 30px;
				}
			}

			.slick-next {
				width: 40px;
				height: 40px;
				background: #191D22;
				border-radius: 8px;
				position: absolute;
				top: -65px;
				right: 62px;

				@media(max-width:1199px) {
					right: 15px;
				}

				@media(max-width:575px) {
					top: -10px;
					right: 15px;
				}

				img {
					transform: rotate(-90deg);
					opacity: 0.5;
				}

				&:before {
					display: none;
				}

				&:hover {
					img {
						opacity: 1;
					}
				}
			}

			.slick-prev {
				width: 40px;
				height: 40px;
				background: #191D22;
				border-radius: 8px;
				position: absolute;
				top: -65px;
				right: 112px;
				left: auto;

				@media(max-width:1199px) {
					right: 65px;
				}

				@media(max-width:575px) {
					top: -10px;
					right: 65px;
				}

				img {
					transform: rotate(90deg);
					opacity: 0.5;
				}

				&:before {
					display: none;
				}

				&:hover {
					img {
						opacity: 1;
					}
				}
			}

			/* the slides */
			.slick-slide {
				padding: 0 14px;
				max-height: 318px;

				@media (max-width: 1199px) {
					padding: 0 28px 0 0px;
				}
			}
		}
	}
}

.slick-slide .noItemFound img {
	margin: 0 auto;
}


.gradientHeading {
	font-family: Russo One;
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: 60px;
	text-shadow: 0px 0px 12px rgba(246, 246, 246, 0.3);
	background: var(--dark-gradient, linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 36px;

	@media(max-width:767px) {
		font-size: 30px;
	}
}

.gradientBtn {
	display: flex;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
	color: var(--dark-bg-1);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	transition: all 0.5s;

	&:hover {
		background: linear-gradient(137deg, #191D22 6.3%, #2E343D 95.08%);
		color: #FFA900;
	}
}

.darkBtn {
	display: flex;
	min-width: 160px;
	height: 50px;
	padding: 8px 20px;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 8px;
	font-weight: 600;
	background: #1F2329;
	transition: all 0.5s;

	&:hover {
		background: #FFA900;
		color: #1F2329;

		svg {
			path {
				fill: var(--dark-bg-1) !important;
			}
		}
	}
}

.btn160 {
	min-width: 160px;
}

.text-danger {
	color: #FA0B00 !important;
}

.text-success {
	color: #1BFA70 !important;
}

.ylwTxt {
	color: #FFDF6F !important;
}

.yellowTxt {
	color: #FFA900 !important;
}

.gryTxt {
	color: var(--dark-text-secondary) !important;
}

.boldTxt {
	font-weight: 600 !important;
}

.altFont {
	font-family: var(--font-russo) !important;
}

/*E O Home page style*/

.walletIconCnt {
	width: 30%;
	max-width: 190px;
	padding: 20px;
	display: flex;
	padding: 20px;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	flex: 1 0 0;
	border-radius: 16px;
	border: 2px solid #2E343D;
	background: #15171C;
	margin: 0px 2%;
	position: relative;
	transition: all 0.2s;

	@media (max-width: 525px) {
		width: 50%;
		max-width: 50%;
		margin-bottom: 10px;
	}

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		border-radius: 8px;
		background: #15171C;
		z-index: 2;
		transition: all 0.2s;
	}

	h3 {
		&.heading {
			color: var(--dark-text-secondary);
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
			margin-bottom: 0px;
			position: relative;
			z-index: 3;
		}
	}

	img {
		position: relative;
		z-index: 3;
	}

	&:hover {
		background: rgb(255, 169, 0);
		background: linear-gradient(134deg, rgba(255, 169, 0, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 237, 174, 1) 100%);
		cursor: pointer;

		&:before {
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			top: 2px;
			left: 2px;
		}
	}

}

@keyframes loader {

	0% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(180deg);
	}

	50% {
		transform: rotate(360deg);
	}

	75% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

.loaderCont {
	position: relative;

	.loaderAnimCnt {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-21px, -15px);
		z-index: 3;

		img {
			animation: loader 3s infinite;
		}

	}
}

.tooltipCnt {
	background-color: var(--dark-alert-tip);
}

// *******************hometrending***********************

.mb-36 {
	margin-bottom: 36px;
}

.mb-20 {
	margin-bottom: 20px;
}

.TraSec {
	padding: 30px 0px;
	padding-top: 90px;
}

.trad_tab_hd {
	border-radius: 8px;
	background: #191D22;
	backdrop-filter: blur(19px);
	border-bottom: 0px;

	li {
		margin-right: 5px;

		&:last-child {
			margin-right: 0px;
		}

		a {
			border-radius: 8px;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			border: none;
			color: var(--dark-text-secondary);
			font-weight: 600;

			box-shadow: none;

			&:hover,
			&.active {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: var(--dark-bg-1) !important;
				font-weight: 600;
				transition: 0.5s ease-in-out;
				box-shadow: none;
				border: none;
				border-top-left-radius: 12px;
				border-top-right-radius: 12px;
			}

			&:hover {
				cursor: pointer;
			}

		}
	}
}

// .hrs_tab_hd {
// 	border-radius: 8px;
// 	background: #191D22;
// 	backdrop-filter: blur(19px);
//    border-bottom:0px;

// 	li {
// 		a {
// 			color: var(--dark-text-secondary);
// 			text-align: center;
// 			font-size: 16px;
// 			font-style: normal;
// 			font-weight: 600;
// 			background-color: transparent;
// 			box-shadow: none;
// 			&:hover,
// 			&.active {
// 				border-radius: 8px;
// 				background: #2E343D!important;
// 				color: #FFA900!important;
// 				box-shadow: none;
//             border:none;

// 			}

//          &:hover{
//             cursor:pointer;
//          }
// 		}
// 	}
// }
.trd_head {
	tr {
		th {
			color: #595F6A;
			text-align: right;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;

			&:nth-child(1) {
				text-align: start;
			}

			&:nth-child(2) {
				text-align: start;
			}
		}
	}
}

.trdtable_body {
	tr {
		td {
			color: #F6F6F6;
			text-align: right;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;
			vertical-align: middle;

			&:nth-child(1) {
				text-align: start;
			}

			&:nth-child(2) {
				text-align: start;
			}

			&.red {
				color: #FA0B00 !important;
			}

			&.green {
				color: #1BFA70 !important;
			}

			&.gld {
				color: #FFDF6F;
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
			}

			h1 {
				overflow: hidden;
				color: #F6F6F6;
				text-overflow: ellipsis;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				max-width: 107px;
				margin-bottom: 0px;
			}

		}
	}

}

.artcrd {
	border-radius: 8px;
	background: #191D22;
	color: #F6F6F6;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding: 2px 7px;
	margin-bottom: 10px;
	cursor: pointer;

	span {
		padding: 5px;
	}

	.crs {
		cursor: pointer;
	}
}

.filtl {
	border-radius: 16px;
	background: #15171C;
	padding: 20px 20px;

	h1 {
		color: var(--dark-text-secondary);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 20px;
	}

	h2 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 0px;
	}
}

.hdfil {
	.rntar {
		transition: 0.6s ease-in-out;
	}

}

.hdfil[aria-expanded="true"] {

	.rntar {
		rotate: 180deg;
		transition: 0.6s ease-in-out;
	}

}

.bxnflt {
	border-radius: 8px;
	background: #191D22;
	padding: 16px 16px;

	h3 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}


}

// .cuschk {


// 	display: block;
// 	position: relative;
// 	cursor: pointer;
// 	height: 20px;
// 	width: 20px;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;


// 	/* Hide the browser's default checkbox */
// 	input {
// 		position: relative;
// 		opacity: 0;
// 		cursor: pointer;
// 		height: 0;
// 		width: 0;
// 	}

// 	/* Create a custom checkbox */
// 	.checkmark {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		height: 20px;
// 		width: 20px;
// 		background-color: #191D22;
// 		border: 1px solid #2E343D;
// 		border-radius: 3px;
// 	}

// 	/* On mouse-over, add a grey background color */
// 	&:hover input~.checkmark {
// 		background-color: #181B20;
// 		border: 1px solid #2E343D;
// 		border-radius: 3px;
// 	}

// 	/* When the checkbox is checked, add a blue background */
// 	input:checked~.checkmark {
// 		background-color: #FFA900;
// 		border: 1px solid #FFA900;
// 		border-radius: 3px;
// 	}

// 	/* Create the checkmark/indicator (hidden when not checked) */
// 	.checkmark:after {
// 		content: "";
// 		position: absolute;
// 		display: none;
// 	}

// 	/* Show the checkmark when checked */
// 	input:checked~.checkmark:after {
// 		display: block;
// 	}

// 	/* Style the checkmark/indicator */
// 	.checkmark:after {
// 		left: 7px;
// 		top: 3px;
// 		width: 5px;
// 		height: 10px;
// 		border: solid var(--dark-bg-1);
// 		border-width: 0 1.5px 1.5px 0;
// 		-webkit-transform: rotate(45deg);
// 		-ms-transform: rotate(45deg);
// 		transform: rotate(45deg);
// 	}
// }


.bri_pagenation2 {
	margin: 20px 0px;

	.pagination {
		.page-link {
			border-radius: 2px;
			background: #15171C;
			border: none;
			color: #FFF;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			margin: 2px;
			border-radius: 8px;
			padding: 10px;
			margin: 6px;
			min-width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: #000;

			}
		}

		.page-link-1 {

			&:hover {
				svg {
					path {
						fill: #000;
					}
				}
			}
		}

		.clog {
			margin: 6px;
			color: #595F6A;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;

		}
	}
}

.nthhkj {

	h1 {
		color: #F6F6F6;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}

	p {
		color: var(--dark-text-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		max-width: 230px;
		margin: auto;
	}

}


@media only screen and (max-width: 991px) {
	.mb-36 {
		margin-bottom: 16px;
	}

	.bri_pagenation2 .pagination .page-link {
		min-width: 35px;
		min-height: 35px;
		margin: 3px;
		padding: 5px;
	}
}


// *******************hometrending***********************

.mb-36 {
	margin-bottom: 36px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mt-20 {
	margin-top: 20px;

}

.TraSec {
	padding: 30px 0px;
	padding-top: 90px;
}

.trad_tab_hd {
	border-radius: 8px;
	background: #191D22;
	backdrop-filter: blur(19px);
	min-height: 40px;

	li.nav-item {
		a.nav-link {
			border-radius: 8px;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			border: 0px;
			color: var(--dark-text-secondary);
			font-weight: 600;
			transition: 0.5s ease-in-out;
			box-shadow: none;

			&:hover,
			&.active {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: var(--dark-bg-1);
				font-weight: 600;
				box-shadow: none;
			}

		}
	}
}

.hrs_tab_hd {
	border-radius: 8px;
	background: #191D22;
	backdrop-filter: blur(19px);
	border: 0px;
	min-height: 40px;

	li.nav-item {
		a.nav-link {
			color: var(--dark-text-secondary);
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			background-color: transparent;
			box-shadow: none;
			border-radius: 8px;
			border: 0;

			&:hover,
			&.active {
				background: #2E343D;
				color: #FFA900;
				box-shadow: none;
			}
		}
	}
}

.trd_head {
	tr {
		th {
			color: #595F6A;
			text-align: right;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;

			&:nth-child(1) {
				text-align: start;
			}

			&:nth-child(2) {
				text-align: start;
			}
		}
	}
}

.trdtable_body {
	tr {
		td {
			color: #F6F6F6;
			text-align: right;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;
			vertical-align: middle;

			&:first-child {
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
			}

			&:last-child {
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
			}

			&:nth-child(1) {
				text-align: start;
			}

			&:nth-child(2) {
				text-align: start;
			}

			&.red {
				color: #FA0B00 !important;
			}

			&.green {
				color: #1BFA70 !important;
			}

			&.gld {
				color: #FFDF6F;
				text-align: center;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
			}

			&.orncl {
				color: #FFA900;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 120px;
			}

			h1 {
				overflow: hidden;
				color: #F6F6F6;
				text-overflow: ellipsis;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				max-width: 107px;
				margin-bottom: 0px;
			}

			h2 {
				color: #F6F6F6;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				margin-bottom: 0px;

			}

			p {
				color: var(--dark-text-secondary);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 0px;
			}

			h3 {
				color: #F6F6F6;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 0px;
			}


		}

		&:hover {
			background-color: var(--dark-bg-2);
		}
	}

}

.artcrd {
	border-radius: 8px;
	background: #191D22;
	color: #F6F6F6;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding: 2px 7px;
	margin-bottom: 10px;
	cursor: pointer;

	span {
		padding: 5px;
	}

	.crs {
		cursor: pointer;
	}
}

.filtl {
	border-radius: 16px;
	background: #15171C;
	padding: 20px 20px;

	h1 {
		color: var(--dark-text-secondary);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 20px;
	}

	h2 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 0px;
	}
}

.hdfil {
	.rntar {
		transition: 0.6s ease-in-out;
	}

}

.hdfil[aria-expanded="true"] {

	.rntar {
		rotate: 180deg;
		transition: 0.6s ease-in-out;
	}

}

.bxnflt {
	border-radius: 8px;
	background: #191D22;
	padding: 16px 16px;
	max-height: 276px;
	overflow: auto;

	h3 {
		color: #F6F6F6;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}



}

.bxnflt,
body {
	scrollbar-width: thin;
	scrollbar-color: #2E343D transparent;
}

.bxnflt::-webkit-scrollbar {
	width: 4px;
	height: 8px;
}

.bxnflt::-webkit-scrollbar-track {
	background-clip: content-box;
	border: 2px solid transparent;
}

.bxnflt::-webkit-scrollbar-thumb {
	background-color: #2E343D;
}

.bxnflt::-webkit-scrollbar-thumb:hover {
	background-color: #2E343D;
}

.bxnflt::-webkit-scrollbar-corner,
.bxnflt::-webkit-scrollbar-track {
	background-color: transparent;
}



body::-webkit-scrollbar {
	width: 10px;
	height: 8px;
}

body::-webkit-scrollbar-track {
	background-clip: content-box;
	border: 2px solid transparent;
}

body::-webkit-scrollbar-thumb {
	background-color: #2E343D;
	border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
	background-color: #2E343D;
}

body::-webkit-scrollbar-corner,
body::-webkit-scrollbar-track {
	background-color: transparent;
}


.cuschk {
	display: block;
	position: relative;
	cursor: pointer;
	height: 20px;
	width: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;


	/* Hide the browser's default checkbox */
	input {
		position: relative;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #191D22;
		border: 1px solid #2E343D;
		border-radius: 3px;
	}

	/* On mouse-over, add a grey background color */
	&:hover input~.checkmark {
		background-color: #181B20;
		border: 1px solid #2E343D;
		border-radius: 3px;
	}

	/* When the checkbox is checked, add a blue background */
	input:checked~.checkmark {
		background-color: #FFA900;
		border: 1px solid #FFA900;
		border-radius: 3px;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	input:checked~.checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.checkmark:after {
		left: 7px;
		top: 3px;
		width: 5px;
		height: 10px;
		border: solid var(--dark-bg-1);
		border-width: 0 1.5px 1.5px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}


.bri_pagenation2 {
	margin: 20px 0px;

	.pagination {
		.page-item {
			&.active {
				.page-link {
					background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
					color: #000;
				}
			}
		}

		.page-link {
			border-radius: 2px;
			background: #15171C;
			border: none;
			color: #FFF;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			margin: 2px;
			border-radius: 8px;
			padding: 10px;
			margin: 6px;
			min-width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover,
			&.active {
				background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
				color: #000;

			}
		}

		.page-link-1 {

			&:hover {
				svg {
					path {
						fill: #000;
					}
				}
			}
		}

		.clog {
			margin: 6px;
			color: #595F6A;
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;

		}
	}
}


.nthhkj {

	h1 {
		color: #F6F6F6;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
	}

	p {
		color: var(--dark-text-secondary);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		max-width: 230px;
		margin: auto;
	}

}


@media only screen and (max-width: 991px) {
	.mb-36 {
		margin-bottom: 16px;
	}

	.bri_pagenation2 .pagination .page-link {
		min-width: 35px;
		min-height: 35px;
		margin: 3px;
		padding: 5px;
	}

	.trad_tab_hd li.nav-item a.nav-link {
		font-size: 14px;
	}
}

// ****************homeactive*******************

.liscdbs {
	background-color: #191D22;
	padding: 8px 8px;
	border-radius: 8px;
	margin-bottom: 0px;
	text-transform: capitalize;
}

.sergps {
	border-radius: 8px;
	border: 1px solid #2E343D;
	background: #191D22;

	input {
		background: transparent;
		border: none;
		box-shadow: none;
		color: var(--dark-white);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		min-height: 40px;

		&:focus {
			background: transparent;
			border: none;
			box-shadow: none;
			color: var(--dark-white);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			min-height: 40px;

		}
	}

	.input-group-text {
		background: transparent;
		border: none;
	}
}

.noResf {
	h1 {
		color: #F6F6F6;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 8px;
	}

	p {
		color: var(--dark-text-secondary);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		margin-bottom: 8px;

	}
}

.tooltip-inner {
	background-color: var(--dark-alert-tip) !important;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: var(--dark-alert-tip) !important;
	/* Red */
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
	border-top-color: var(--dark-alert-tip) !important;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-left-color: var(--dark-alert-tip) !important;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
	border-right-color: var(--dark-alert-tip) !important;
}

.red-tooltip+.tooltip.top>.tooltip-arrow {
	background-color: var(--dark-alert-tip) !important;
}


.fs-12 {
	font-size: 12px;
}


.fs-14 {
	font-size: 14px;
}

.fw-400 {
	font-weight: 400;
}

.fs-16 {
	font-size: 16px;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}


.dark-text-secondary {
	color: var(--dark-text-secondary, #C5C5C5) !important;
}

.dark-text {
	color: var(--dark-text, #F6F6F6) !important;
}

.dark-primary {
	color: var(--dark-primary-1, #FFA900) !important;
}

.whiteTxt {
	color: #fff !important;
}

.hover {
	cursor: pointer;
}


.alertToast {
	// position: fixed;
	// top: 0;
	// left: 50%;
	// transform: translateY(-500px) translateX(-50%);
	// z-index: 999;
	padding: 12px;
	border-radius: 8px;
	background: var(--dark-alert-tip, #323740);
	box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.76);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: var(--dark-text, #f6f6f6);

	// transition: transform 0.5s linear;
	// &.show{
	// 	transform: translateY(10px) translateX(-50%);
	// }
	// &.hide{
	// 	transform: translateY(-500px) translateX(-50%);
	// }
	.d-flex {
		gap: 12px;
	}
}

.closeToastBtn {
	background: transparent;
	border: 0;
	padding: 0;
}

.go2072408551 {
	background: none !important;
}

// .goog-te-banner-frame {
// 	display: none !important;
// 	margin-top: -20px;
// }

// .goog-logo-link {
// 	display: none !important;
// }

// .goog-te-gadget {
// 	color: transparent !important;
// }

// .VIpgJd-ZVi9od-l4eHX-hSRGPd {
// 	display: none !important;
// }

// .goog-te-combo option[value="fr"] {
// 	/* background: url('../src/Images/usa.png') no-repeat; */
// 	padding-left: 20px;
// 	/* Adjust as needed to give space for flag */
// }

// .goog-te-gadget .goog-te-combo {
// 	margin: 7px 0 0 5 !important;
// 	width: 150px !important;
// 	position: relative;
// 	height: 40px;
// 	border-radius: 5px;
// }


// **********************Scroll ***********************

.scroll_btn {
	position: fixed;
	border: none;
	border-radius: 100px;
	width: 50px;
	right: 15px;
	bottom: 30px;
	height: 50px;
	cursor: pointer;
	background-color: #e6a23c;
	z-index: 5;
}



.loader {
	border: 4px solid #bbb6b6;
	border-left-color: transparent;
	border-radius: 50%;
}

.loader {
	border: 4px solid #bbb6b6;
	border-left-color: transparent;
	width: 36px;
	height: 36px;
}

.loader {
	border: 4px solid #bbb6b6;
	border-left-color: transparent;
	width: 36px;
	height: 36px;
	animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.custom-switch.customSwitch {
	.custom-control-label {
		&::before {
			width: 3rem;
			height: 1.5rem;
			border-radius: 50px;
			background-color: var(--light-text);
			border: 0;
		}

		&::after {
			width: 20px;
			height: 20px;
			top: calc(0.3rem + 2px);
			left: calc(-2.1rem + 2px);
			background-color: var(--dark-white);
		}
	}

	.custom-control-input:checked~.custom-control-label {
		&::before {
			background-color: var(--Primary-1);
		}

		&::after {
			transform: translateX(1.5rem);
		}
	}
}


:root {
	--light-grey: #21252B;
	--dark-white: #FFFFFF;
	--light-bg-1: #FFF;
	--dark-bg-1: #0A0A0B;
	--light-input-button: #E0E3E7;
	--light-text: #14161B;
	--light-text-secondary: #474C55;
	--light-text-grey: #73767D;
	--light-borders-hover-button: #D5D8DC;
	--light-bg-2: #F2F3F5;
	--light-alert-tip: #E4E6E9;
	--light-gradient-light: linear-gradient(137deg, #FFAB00 6.3%, #FFD76F 95.08%);
	--Primary-1: #FFA800;
}

@import 'assets/scss/mycollecion_mint.scss';
@import 'assets/scss/collectionmodule.scss';
@import 'assets/scss/filterInputItem.scss';
@import 'assets/scss/filtersection.scss';
@import 'assets/scss/gridviewlist.scss';
@import 'assets/scss/listviewItem.scss';
@import 'assets/scss/NftDetails.scss';


/*Light Mode style*/

body.light-mode {
	background-color: var(--dark-white);
	color: var(--dark-bg-1) !important;

	.collectionActivitySec .card {
		background: var(--light-bg-2);
		border-color: transparent;

		.nav-tabs {
			background: var(--light-input-button);

			li {
				a {
					color: var(--light-text-grey);
					background-color: transparent;

					&.active,
					&:hover {
						background: var(--light-gradient-light);
						color: var(--Button-Text, #0A0A0B);
					}
				}

				&:last-child {
					a {
						margin-right: 0px;
					}
				}
			}

			&.checkTab {
				li {
					a {

						&:hover,
						&.active {
							background: var(--light-borders-hover-button);
							color: var(--Primary-1, #FFA800);
						}
					}
				}
			}
		}

		select {
			border: 1px solid var(--light-borders-hover-button);
			background: var(--light-input-button) url('/assets/images/angleDown-dark.svg') no-repeat right 16px center;
			color: var(--light-text, #14161B);
		}

		table tbody tr td {
			color: var(--light-text);

			h3 {
				color: var(--light-text);
			}

			.labelCnt {
				background: var(--light-input-button);

				img {
					filter: brightness(0.5);
				}
			}
		}
	}

	.gradientHeading {
		background: var(--light-gradient-light);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.gradientBtn {
		background: var(--light-gradient-light);

		&:hover {
			color: var(--light-text);
		}
	}

	.ylwTxt {
		color: var(--Primary-1) !important;
	}

	.gryTxt {
		color: var(--light-text-secondary) !important;
	}

	.tabSliderSec .nav-tabs {
		background: var(--light-input-button);

		li {
			a {
				color: var(--light-text-grey);
				background-color: transparent;

				&.active,
				&:hover {
					background: var(--light-gradient-light);
					color: var(--Button-Text, #0A0A0B);
				}
			}

			&:last-child {
				a {
					margin-right: 0px;
				}
			}
		}

		&.checkTab {
			li {
				a {

					&:hover,
					&.active {
						background: var(--light-borders-hover-button);
						color: var(--Primary-1, #FFA800);
					}
				}
			}
		}
	}

	.tabSliderSec,
	.FeaturedSec {
		.slick-slider {

			.slick-prev,
			.slick-next {
				background: var(--light-input-button);

				img {
					filter: brightness(0);
				}
			}
		}
	}

	.tabSliderSec,
	.FeaturedSec {
		.card {
			.infoCnt {
				background: rgba(255, 255, 255, 0.80);
				fill: rgba(255, 255, 255, 0.80);

				h3.heading {
					color: var(--light-text);
				}

				.valueCnt {
					color: var(--light-text-secondary);

					span {
						color: var(--light-text);
					}
				}
			}
		}
	}

	.dark-text-secondary {
		color: var(--light-text-secondary) !important;
	}

	.dark-text {
		color: var(--light-text) !important;
	}

	.trad_tab_hd {
		background: var(--light-input-button);

		li.nav-item {
			a.nav-link {
				color: var(--light-text-grey);
				background-color: transparent;
			}
		}
	}

	.hrs_tab_hd {
		background: var(--light-input-button);

		li.nav-item {
			a.nav-link {
				color: var(--light-text-grey);
				background-color: transparent;

				&:hover,
				&.active {
					background-color: var(--light-text-grey);
					color: var(--Primary-1);
				}
			}
		}
	}

	.artcrd {
		background: var(--light-input-button);
		color: var(--light-text);
	}

	.filtl {
		background-color: var(--light-bg-2);

		h1 {
			color: var(--light-text-secondary);
		}

		h2 {
			color: var(--light-text);

			&+img {
				filter: brightness(0);
			}
		}

		.bxnflt {
			background-color: var(--light-bg-1);

			h3 {
				color: var(--light-text-grey);
			}
		}
	}

	.noResf h1 {
		color: var(--light-text-secondary);
	}

	.cuschk .checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #e0e3e7;
		border: 1px solid #d5d8dc;
		border-radius: 3px;
	}

	.cuschk+h3 {
		color: var(--light-text);
	}

	.hmecollection {
		.trd_head tr th {
			color: var(--light-text-secondary);
		}

		.trdtable_body tr {
			&:hover {
				background-color: var(--light-bg-2);
			}
		}

		.trdtable_body tr td {
			color: var(--light-text);

			h1 {
				color: var(--light-text-secondary);
			}
		}

		.trdtable_body tr td button {
			background: var(--light-input-button);
			color: var(--light-text);

			span {
				background: transparent;
				background-clip: unset;
				-webkit-text-fill-color: unset;
			}

			&:hover {
				background: var(--light-gradient-light);

				span {
					background: transparent;
					background-clip: unset;
					-webkit-text-fill-color: unset;
				}
			}
		}

		.trdtable_body .liscdbs {
			background-color: var(--light-bg-2);
			color: var(--light-text);

			img {
				filter: brightness(0.3);
			}
		}

		.tableTag {
			background: var(--light-input-button);
			color: var(--light-text);

			img {
				filter: brightness(0.3);
			}

			svg path[fill="white"] {
				fill: var(--light-text);
			}
		}

		.trdtable_body tr td h3,
		.trdtable_body tr td h2 {
			color: var(--light-text);
		}

		.trdtable_body tr td p {
			color: var(--light-text-secondary);
		}
	}

	.sergps {
		border: 1px solid var(--light-borders-hover-button);
		background: var(--light-bg-2);

		.input-group-text {
			img {
				filter: brightness(0.5);
			}
		}

		input {
			color: var(--light-text);

			&:focus {
				color: var(--light-text);
			}
		}
	}

	.bri_pagenation2 {
		.pagination {
			.page-link {
				background: var(--light-input-button);
				color: var(--light-text);

				svg {
					path {
						fill: var(--light-text-secondary);
					}
				}

				&:hover {
					background: linear-gradient(137deg, #FFA900 6.3%, #FFEDAE 95.08%);
					color: var(--dark-text);
				}
			}

			.clog {
				color: var(--light-text);
			}
		}
	}

	.darkBtn {
		background-color: var(--light-bg-1);
		color: var(--light-text);
	}

	.whiteTxt {
		color: var(--light-text) !important;
	}

}

/*Light Mode style*/

.text-center {
	text-align: center;
}

.iconBtn {
	border-radius: 8px;
	background: var(--dark-input-button, #191d22);
	border: 0px;
	width: 40px !important;
	height: 40px !important;
	text-align: center;
	box-shadow: none !important;
	outline: none !important;

	&:hover,
	&:focus {
		background: var(--dark-gradient,
				linear-gradient(137deg, #ffa900 6.3%, #ffedae 95.08%)) !important;
	}
}

.cursor-default {
	cursor: default;
}

.text-decoration-none {
	text-decoration: none;
}

.color-inherit {
	color: inherit !important;
}

.cardValue {
	&:hover {
		opacity: 75%;
		;
	}
}


.imgbrd {
	border-radius: 10px;
}


body.light-mode {
	.formInputs {
		.input-group {
			border-radius: 8px;
			border: 1px solid var(--light-borders-hover-button, #D5D8DC);
			background: var(--light-input-button, #E0E3E7);
			backdrop-filter: blur(19px);

			.form-control {
				color: var(--light-text-grey, #73767D);
				border: 0;
			}

			.input-group-text {
				svg {
					path {
						fill: #21252B;
					}
				}
			}
		}

		.form-control {
			border: 1px solid var(--light-borders-hover-button);
			background-color: var(--light-input-button);
			color: var(--light-text);

			&:focus {
				outline: 0;
				box-shadow: none;
				border-color: #FFA900;
			}
		}

		select {
			appearance: none;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23474C55%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23474C55%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");

		}
	}

	.customdropdown {
		button.btn {
			border-radius: 8px;
			border: 1px solid var(--light-borders-hover-button, #D5D8DC);
			background: var(--light-input-button, #E0E3E7);
			color: var(--light-text, #14161B);

			&:focus {
				outline: 0;
				box-shadow: none;
				border-color: #FFA900;
			}
		}

		.dropdown-menu {
			border-radius: 8px;
			background: rgba(224, 227, 231, 0.70);
			backdrop-filter: blur(19px);
		}

		.dropdown-item {
			color: var(--light-text, #F6F6F6);

			&:hover,
			&:focus {
				background: var(--light-gradient-light);
				color: var(--dark-bg-1);
			}
		}
	}

	.ItemsTabsViewTabs {
		border-radius: 8px;
		background: var(--light-input-button, #E0E3E7);
		backdrop-filter: blur(19px);

		li.nav-item {
			a.nav-link {
				svg {
					[fill="white"] {
						fill: #73767D;
					}
				}

				&.active {
					border-radius: 8px;
					background: var(--light-borders-hover-button, #D5D8DC);
				}
			}
		}
	}

	.primeBtn {
		border-radius: 8px;
		background: var(--light-input-button, #E0E3E7);
		color: var(--light-text, #14161B);

		&:hover {
			background: var(--light-gradient-light);
			color: var(--dark-bg-dark-1, #0A0A0B);
		}
	}

	.selectFloatCnt {
		background: var(--light-bg-2);
		box-shadow: 2px 0px 20px 0px rgba(255, 255, 255, 0.16);

		.btn {
			background: var(--light-input-button);
			color: var(--light-text);

			&:hover,
			&.active {
				background: var(--light-gradient-light);
				color: var(--light-Text);
			}
		}

		a {
			color: var(--light-text-grey);

			&:hover,
			&.active {
				color: var(--Primary-1, #FFA800);
			}
		}
	}
}


body.light-mode .profile_userDetails__s83dR .userName {
	color: var(--light-text, #14161B);
}

.du-wh00 {
	.dropdown-menu {
		width: 100%;
	}
}

.hdr_hdrMenu__6dGk7 {
	.btn-group {
		display: unset;
	}
}



@media only screen and (max-width: 1200px) {

	.hdr_balanceShow__6Xiyj {
		margin-bottom: 10px;
	}


}

.mt-36 {
	margin-top: 36px;
}

input:focus{
	box-shadow: none !important;
}

@media only screen and (min-width: 1200px) {
	.container-fluid {
		// max-width: calc(100% - 124px);
		max-width: calc(100% - 94px);
	}
}