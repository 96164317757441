

.cl_banner{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: calc(100% - 96px);
        background: linear-gradient(180deg, #090A0C 0%, rgba(9, 10, 12, 0.62) 53.12%, rgba(9, 10, 12, 0.00) 100%);
    }
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #090A0C 0%, rgba(9, 10, 12, 0.62) 38.19%, rgba(9, 10, 12, 0.00) 100%);
        height: 96px;
    }
    
}