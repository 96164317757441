.bnr {
   // padding-top: 72px;
   position: relative;
}

.bnrIg {
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   z-index: -1;
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #090A0C 15.94%, rgba(9, 10, 12, 0.52) 100%);
   }
}


.bnrSld {
   position: relative;
}

.bnrCon {
   padding: 155px 0px 82px;
   display: flex !important;
   flex-direction: column;
   gap: 24px;
   align-items: center;
   justify-content: center;
   @media only screen and (max-width: 575px)
   {
      gap: 14px;
      padding: 135px 0px 62px;
      text-align: center;
   }

   :global(.slick-slide) & img {
      display: inline-block;
   }

   h2 {
      font-size: clamp(28px, 4.5vw, 48px);
      color: var(--dark-text, #F6F6F6);
      font-weight: 400;
      font-family: var(--font-russo);
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
         padding: 0px 70px;
      }
      span {
         margin-right: 12px;
         @media only screen and (max-width: 575px)
         {
            margin-right: 8px;
         }
      }

      img {
         vertical-align: middle;
         width: clamp(20px, 4vw, 40px);
      }

      margin: 0px;
   }
}

.atrIg {
   img {
      border-radius: 8px;
      box-shadow: 0px 0px 27px 0px #FFF;
   }
}

.atrNm {
   margin: 0px;
   color: var(--dark-text-secondary, #C5C5C5);
   font-size: 16px;
   font-weight: 400;
   margin-bottom: 8px;
   strong {
      font-weight: 600;
   }

   span {
      margin-right: 4px;
      vertical-align: middle;
   }
}

.vlmFlr {
   display: inline-flex;
   align-items: center;
   flex-wrap: wrap;
   gap: 20px;
   margin: 0px;
   justify-content: center;
   @media (max-width: 375px) {
      padding: 0px 30px;
   }
}

.vlmCon {
   font-size: 16px;
   color: var(--dark-white, #ffffff);
   font-weight: 400;

   strong {
      font-weight: 600;
   }
}

.flrCon {
   font-size: 16px;
   color: var(--dark-text-secondary, #C5C5C5);
   font-weight: 400;

   strong {
      font-weight: 600;
   }
}


.bnrLk {
   position: relative;
   z-index: 1;
   a {
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.16);
      backdrop-filter: blur(19px);
      display: inline-block;
      padding: 8px 20px;
      color: var(--dark-text, #F6F6F6);
      text-decoration: none;
      font-weight: 600;
   }
}

.btnSld {
   position: absolute;
   top: 50%;
   transform: translate(0px, -50%);
   border-radius: 8px;
   background: rgba(255, 255, 255, 0.16);
   backdrop-filter: blur(19px);
   padding: 7px 9px;
   z-index: 9;
   @media only screen and (max-width: 767px)
   {
      top: 90%;
   }

   &:focus {
      box-shadow: none;
   }

   &.btnSldRt {
      right: 15px;

      img {
         transform: rotate(180deg);
      }
   }
}

.containAbsolute{
   position: relative;
   top: -250px;
   // position: absolute;
   //  z-index: 1;
   //  height: 100%;
   //  left: 50%;
   //  transform: translateX(-50%);
}

