
.fs-14{
    font-size: 14px;
}
.fw-400{
    font-weight: 400;
}
.fs-16{
    font-size: 16px;
}
.fw-600{
    font-weight: 600;
}

.dark-text-secondary{
    color: var(--dark-text-secondary, #C5C5C5);
}
.dark-text{
    color: var(--dark-text, #F6F6F6);
}

.filterofferscard{
    padding: 8px 16px;
    border-radius: 12px;    
    background: var(--dark-input-button, #191D22);
    font-size: 12px;
    font-weight: 400;
    @extend .dark-text-secondary;
    transition: all 0.3s linear;
    &:hover{    
        background: var(--dark-borders-button-text-inactive, #2E343D);  
    }
}

.spanTextRight{
    display: inline-block;
    width: 75px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}